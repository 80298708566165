import React, { ReactNode } from 'react'
import { Accordion as BaseAccordion, AccordionItem } from '@overdose/components'
import classNames from 'classnames'
import { RichTextProps } from '~/components/RichText'
import RichText from '~/components/RichText/RichText'
import styles from './Accordion.module.css'
import { AccordionProps } from './Accordion.types'

export const Accordion = ({
  panels,
  variant = 'default',
  theme,
  multiple,
  onChange,
  backgroundColor,
  borderRadius,
  value,
  defaultValue,
}: AccordionProps) => {
  const isReactNode = (
    element: ReactNode | RichTextProps
  ): element is ReactNode => {
    return React.isValidElement(element)
  }

  const handleAccordionItemRichText = (subItem, index) => {
    return (
      <AccordionItem
        theme={{
          ...theme,
          control: classNames(styles.accordionItem, theme?.control),
        }}
        style={{ backgroundColor }}
        key={index}
        name={subItem.name}
        header={subItem.header}>
        <RichText content={subItem.content} />
      </AccordionItem>
    )
  }

  const handleAccordionItem = (subItem, index) => {
    return (
      <AccordionItem
        style={{ backgroundColor }}
        key={index}
        name={subItem.name}
        header={subItem.header}>
        {subItem.content}
      </AccordionItem>
    )
  }

  return (
    <BaseAccordion
      theme={{
        title: styles.title,
        content_inner: styles.content_inner,
        control: 'px-0 py-10 !bg-white',
        icon: styles.icon,
      }}
      style={{ backgroundColor, borderRadius }}
      variant={variant}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      multiple={multiple}>
      {panels?.map((subItem, index) => {
        if (isReactNode(subItem.content)) {
          return handleAccordionItem(subItem, index)
        }
        return handleAccordionItemRichText(subItem, index)
      })}
    </BaseAccordion>
  )
}
